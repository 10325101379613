.form-table{
  tr{

  }
  td{
    padding: 5px;
    border-bottom: 1px solid #717983;
  }
  input, select{
    padding: 5px;
    font-size: 15px;
    border-radius: 3px;
    border: 1px solid #344955;
    margin: 5px;
  }
  input:focus, select:focus{
    border: 1px solid #344955;
    outline: 1px solid #344955;
  }
  .error{
    border-color: red;
  }
  button{
    margin: 5px;
  }
}